<template>
  <div class="table-v-action-button" @click="exportCsv()">
    <v-dialog
      v-model="show"
      scrollable
      persistent
      max-width="400"
      @keydown="dialogKeyDown($event)"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon> 
          Exportar CSV
          <v-btn
            @click="show = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Requisição entrou na fila para processamento.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="show = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="verFila()"
          > 
            Ver fila
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-icon>mdi-cloud-refresh-outline</v-icon>
    Exportação Assíncrona
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['clientId', 'intervaloCompetencia', 'servico']),
    resource: function () {
      return this.apiResource(`v1/clientes/${this.clientId}/exportacao`);
    },
    parseAnoBase: function () {
      if (!Number.isNaN(parseInt(this.anoBase, 10))) {
        return this.anoBase;
      }

      if (this.anoBase === true) {
        return this.$store.getters.anoBase;
      }

      return undefined;
    },
    parseCompetencia: function () {
      if (Array.isArray(this.competencia)) {
        return this.competencia;
      }

      if (this.competencia === true) {
        return this.intervaloCompetencia;
      }

      return undefined;
    },
    parseBeneficio: function () {
      if (['number', 'string'].includes(typeof this.beneficio)) {
        return this.beneficio;
      }

      if (this.beneficio === true) {
        return this.servico;
      }

      return undefined;
    },
  },
  data: function () {
    return {
      show: false,
    }
  },
  methods: {
    dialogKeyDown: function (keyEvent) {
      const { code } = keyEvent;
      if (code === "Escape") {
        this.show = false;
      }
    },
    exportCsv: function () {
      let body = { tipoRelatorio: this.type };

      if (this.parseAnoBase) {
        body.anoBase = this.parseAnoBase;
      }

      if (this.parseCompetencia) {
        body.competenciaIni = this.parseCompetencia[0];
        body.competenciaFim = this.parseCompetencia[1];
      }

      if (this.parseBeneficio) {
        body.trabalho = this.parseBeneficio;
      }

      this.resource.save(body).then(() => this.show = true);
    },
    verFila: function () {
      this.$router.push({ name: "listagem-relatorios-assincronos" });
    },
  },
  props: {
    anoBase: { type: [Boolean, Number, String], default: () => false},
    beneficio: { type: [Boolean, Number, String], default: () => false},
    competencia: { type: [Boolean, Array], default: () => []},
    type: String,
  }
}
</script>
